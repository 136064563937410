import * as React from "react"
import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Typography from "/src/components/ui/base/typography"
import Header from "../components/ui/base/layout/header"
import Section from "../components/ui/base/Section"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import CourseBanner from "../components/ui/base/CourseBanner"

const ThankYou = () => {
  const { t, i18n: l } = useTranslation()
  const data = useStaticQuery(query)

  const homePage = data.strapiThankYou
  const offers = homePage.offers

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          currencyCode: "EUR",
          items: [
            {
              name: "Tour Deposit",
              id: 0,
              price: 100,
              brand: "WeShoot",
              category: "Viaggi Fotografici",
            },
          ],
        },
      })

      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq("track", "Purchase", {
            value: 100,
            currency: "EUR",
            content_type: "product",
            content_ids: 0,
          })
        }
      }
    }
  }, [])

  return (
    <>
      <Header size={"big"} videoURL={"weshoot-viaggi-fotografici"} theme="dark">
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  {homePage.title}
                </Typography>
                <Typography className="animated fadeInDown" variant="heading2">
                  {homePage.subtitle}
                </Typography>
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section
        className={classnames([styles.description])}
        variant={"white"}
        title={"Non vediamo l'ora di conoscerti..."}
      >
        <div dangerouslySetInnerHTML={{ __html: homePage.body }} />
      </Section>

      <Section
        className={classnames([styles.description])}
        variant={"white"}
        title={"Prima di partire..."}
        subtitle={
          "Approfitta dell'offerta limitata sul corso di fotografia per prepararti a fare foto incredibili durante il tuo viaggio"
        }
      >
        <CourseBanner columns={offers} />
      </Section>
    </>
  )
}
export const query = graphql`
  {
    strapiThankYou {
      body
      title
      subtitle
      offers {
        description
        icon {
          url
          size
          localFile {
            ...ImageFragment
          }
        }
        title
        link
        id
      }
    }
  }
`
export default ThankYou
