import classnames from "classnames"
import React from "react"
import * as styles from "./index.module.scss"
import Typography from "../typography"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../Button"

const CourseBanner = ({ columns }) => {
  return (
    <div className={classnames([styles.columnsContainer])}>
      {columns.map((column, i) => {
        return (
          <div key={i} className={classnames([styles.column])}>
            <div className={classnames([styles.icon])}>
              <GatsbyImage
                image={column.icon.localFile.childImageSharp.gatsbyImageData}
                alt={column.title}
              />
            </div>
            <div className={classnames([styles.info])}>
              <Typography
                variant="heading3"
                className={classnames([styles.title])}
              >
                {column.title}
              </Typography>
              <div className={classnames([styles.description])}>
                {column.description}
              </div>

              <Button
                variant={"link"}
                to={column.link}
                className={classnames([styles.button])}
              >
                Ottieni Corso in Offerta
              </Button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CourseBanner
